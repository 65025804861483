import React from 'react';

const home = () => {
    return (
       <div>
          <h1>AImogen archive</h1>
           <p><a href="/webchats">Online chats with Augmented-Imogen</a></p>
           <p><a href="/videochats">Transcripts of video chats with Real Imogen</a></p>
       </div>
    );
}

export default home;
