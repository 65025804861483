import React, { useState, useEffect } from 'react';

const Interactions = (props) => {
  const [ interactions, setInteractions ] = useState([]);

  useEffect(() => {
    fetch(props.query_url)
      .then(response => response.json())
      .then(json => setInteractions(json.data));
  }, [props.query_url])

  return (
    <div>
      <h1>Interactions</h1>
      {interactions.length === 0
          ? 'Loading interactions...'
          : <table>
              <tbody>
              <tr>
                  <td><b>Asked on</b></td>
                  <td><b>Question</b></td>
                  <td><b>{props.videochat
                  ? 'Rendered emotion transcript'
                  : 'AI intent recognition data'
                  }</b> </td>
              </tr>
                {interactions.map(interaction => (
                    <tr key={interaction.id} >
                      <td width="10%">{interaction.asked_on}</td>
                      <td width="40%">{interaction.transcript}</td>
                      <td width="50%">{props.videochat
                      ? <a href={((interaction.emotion_transcript || {}).data || {}).full_url}>
                        <img style={{'max-height':'100px', 'max-width':'100%'}} src={((interaction.emotion_transcript || {}).data || {}).full_url} alt="emotion_transcript"/></a>
                      : <small>{interaction.ai_debug}</small>
                    } </td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
      }
    </div>
  );
}

export default Interactions
