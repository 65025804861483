import React from 'react';

import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
       <div id="navbar">
          <NavLink to="/"  style={{padding:10}}>Archive Home</NavLink>
          <NavLink to="/webchats" style={{padding:10}}>Online chats with Augmented Imogen</NavLink>
          <NavLink to="/videochats" style={{padding:10}}>Videochats with Real Imogen</NavLink>
       </div>
    );
}

export default Navigation;
